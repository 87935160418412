<template>
  <q-drawer
    v-if="isOpenQuickstart"
    v-model="isOpenQuickstart"
    show-if-above
    side="right"
    :class="`bg-animated styled-scrollbar ${color}`"
    :width="$q.screen.width < 700 ? $q.screen.width : 500"
    :bordered="appOptions.theme === 'cdek'"
  >
    <div class="text-h6 q-pt-xs q-pb-xl text-center bg-light-blue-6">
      <div class="col-12 row items-center">
        <div class="col q-pl-md">
          <search
            dense
            autoset
            is-expandable
            @submit="handleSearch"
          />
        </div>

        <div class="col text-right">
          <q-btn
            color="transparent"
            text-color="black"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="toggleIsOpenQuickstart"
          />
        </div>
      </div>

      <div class="col-12">
        {{ $t('Quickstart')}}
      </div>
    </div>

    <div
      class="q-pa-md"
      style="margin-top: -50px;"
    >
      <q-list
        bordered
        class="rounded-borders"
        :class="$q.dark.isActive ? 'bg-dark' : 'bg-white text-dark'"
      >
        <div
          v-if="isLoading"
          class="row justify-center items-center text-center q-my-md text-subtitle1"
          style="min-height: 200px;"
        >
          <q-spinner
            color="primary"
            size="3rem"
          />
        </div>

        <help-section-panel
          v-for="section in helpSections"
          :key="section.id"
          :model="section"
        />
      </q-list>
    </div>
  </q-drawer>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import HelpSectionPanel from './HelpSectionPanel.vue'
import Search from '../search/Search.vue'

export default {
  name: 'HelpSectionsPanel',
  components: {
    HelpSectionPanel,
    Search
  },
  data () {
    return {
      isLoading: false,
      helpSections: [],
      search: ''
    }
  },
  computed: {
    ...mapGetters([
      'appOptions',
      'isOpenQuickstart'
    ]),
    color () {
      const colors = {
        cdek: 'bg-light',
        default: 'bg-dark'
      }

      return colors[this.appOptions.theme]
        ? colors[this.appOptions.theme]
        : colors.default
    }
  },
  watch: {
    isOpenQuickstart (value) {
      if (value && this.helpSections.length <= 0) {
        this.loadHelpSections()
      }
    }
  },
  methods: {
    ...mapMutations([
      'toggleIsOpenQuickstart'
    ]),
    handleSearch (search) {
      this.search = search
      this.helpSections = []
      return this.loadHelpSections()
    },
    loadHelpSections () {
      const query = {
        page: 1,
        per_page: 250,
        filter: [
          { type: 'eq', field: 'state', value: 'active' }
        ],
        'order-by': [
          { type: 'field', field: 'priority', direction: 'desc' }
        ],
        search: this.search
      }

      this.isLoading = true
      return this.$service.helpSection.getAll(query)
        .then(({ items }) => {
          this.helpSections = items
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
