<template>
  <q-expansion-item
    :model-value="isOpen"
    expand-separator
    :label="$t(model.name || '')"
    :caption="model.description && $t(model.description) || ''"
    @update:model-value="toggle"
  >
    <q-card>
      <q-card-section
        v-if="isLoading"
        class="row justify-center items-center text-center q-my-md text-subtitle1"
        style="min-height: 200px;"
      >
        <q-spinner
          color="primary"
          size="3rem"
        />
      </q-card-section>

      <q-card-section v-else class="q-py-xs q-px-sm">
        <q-stepper
          v-model="step"
          ref="stepper"
          vertical
          color="light-blue-9"
          class="q-pa-xs"
          header-class="q-pa-xs"
          header-nav
          animated
        >
          <q-step
            v-for="(helpArticle, i) in helpArticles"
            :key="helpArticle.id"
            :name="i"
            :title="$t(helpArticle.name || '')"
            icon="settings"
            class="q-pa-xs q-step--sm-header"
            dense
            :done="step > i"
          >
            <div>
              {{ $t(helpArticle.content) }}
            </div>

            <q-stepper-navigation class="q-pb-none q-pt-sm">
              <q-btn
                @click="step = i + 1"
                color="light-blue-9"
                :label="$t(helpArticles.length <= i + 1 ? 'Finish' : 'Continue')"
                size="sm"
                no-caps
              />

              <q-btn
                v-if="i > 0"
                flat
                @click="step = i - 1"
                color="light-blue-9"
                :label="$t('Back')"
                size="sm"
                class="q-ml-sm"
              />
            </q-stepper-navigation>
          </q-step>
        </q-stepper>
      </q-card-section>
    </q-card>
  </q-expansion-item>
</template>

<script>
export default {
  name: 'HelpSectionPanel',
  props: {
    model: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      step: 0,
      isOpen: false,
      isLoading: false,
      helpArticles: []
    }
  },
  methods: {
    toggle () {
      this.isOpen = !this.isOpen

      if (this.isOpen && this.helpArticles.length <= 0) {
        this.loadHelpArticles(this.model.id)
      }
    },
    loadHelpArticles (section) {
      const query = {
        page: 1,
        per_page: 250,
        filter: [
          { type: 'eq', field: 'state', value: 'active' },
          { type: 'eq', field: 'section', value: section }
        ],
        'order-by': [
          { type: 'field', field: 'priority', direction: 'desc' }
        ]
      }

      this.isLoading = true

      return this.$service.helpArticle.getAll(query)
        .then(({ items }) => {
          this.helpArticles = items
          return items
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style>
.q-stepper__step.q-step--sm-header .q-stepper__tab {
  padding: 4px;
}
</style>